import React from 'react';
import darkLogo from '../../Assets/darkLogo.png'

import './login.sass';
import { Button } from '../../ui/button';
import { UserIcon, X } from 'lucide-react';

const Login = () => {
    return <>
        <div className='bu-cross-icon' onClick={() => {
            localStorage.setItem('showFloatingScreen', false);
            const event = new CustomEvent('localStorageChange', {
                detail: { key: 'showFloatingScreen', value: "false" }
            });
            window.dispatchEvent(event);
            window?.parent?.postMessage({ type: 'extension-closed' }, '*');
        }}>
            <X size={24} />
        </div>
        <div className='bu-login-wrap'>
            <img
                className="bu-logo-img"
                src={darkLogo}
                alt="Logo"
            />


                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>


        </div>
    </>
}

export default Login;
