/*global chrome*/
import "./App.css";
import MainPage from "./Components/MainPage";
import Transcripts from "./Components/Transcripts/index";
import Layout from "./Components/Layout";
import {ThemeProvider} from "next-themes";
import {useEffect, useState} from "react";
import Login from "./Components/Auth";
import Loading from "./Components/Loading";
import {
    generateCode,
    getAuthFromToken,
    getChatHistory,
    getNextMessage,
    SendMessage,
} from "./utils/Chat/chatIntegrationApi";
import Pusher from "pusher-js";
import {toast} from "sonner";

function App() {
    const [showFloatingWindow, setShowFloatingWindow] = useState(false);
    const [showMainScreen, setShowMainScreen] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);

    //chat Handlers
    const [sendMessageLoading, setSendMessageLoading] = useState(true);
    const [channelId, setChannelId] = useState("");
    const [input, setInput] = useState("");
    const [file, setFile] = useState(null);
    const [userMessages, setUserMessages] = useState([]);

    const channel = new BroadcastChannel("build-up-extension-channel");
    const [loading, setLoading] = useState(false);

    const [waitingForAgent, setWaitingForAgent] = useState(true);
    const [authChanged, setAuthChanged] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const buildupToken = urlParams?.get("token");


        if (buildupToken) {
            setLoading(true);
            console.log("Got buildup token", buildupToken);
            getAuthTokenFromDB(buildupToken)
                .then((accessToken) => {
                    if (accessToken) {
                        localStorage.setItem("token", accessToken);
                        setAuthChanged(Date.now);
                        const iframe = document.getElementById("build-up-extension-iframe");
                        iframe.contentWindow.postMessage(
                            {
                                type: "AUTH_TOKEN",
                                token: accessToken,
                            },
                            "*"
                        );
                        window.postMessage(
                            {
                                type: "AUTH_TOKEN",
                                token: accessToken,
                            },
                            "*"
                        );
                        setIsAuthorized(true);

                        setTimeout(() => {
                            window.close();
                        }, 2000);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);


    useEffect(() => {
        console.log("Checking if token exists");
        const token = localStorage.getItem("token");
        if (token) {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
    }, [authChanged]);


    useEffect(() => {
        window.addEventListener('message', event => {
            console.log("Message received", event.data);
            if (event.data.type === 'AUTH_CHANGED') {
                setAuthChanged(!authChanged);
            }
        });
    }, []);


    useEffect(() => {
        const checkLocalStorage = () => {
            const showFloatingScreenStored =
                localStorage.getItem("showFloatingScreen") === "true";
            if (showFloatingScreenStored !== showFloatingWindow)
                setShowFloatingWindow(showFloatingScreenStored);
        };

        checkLocalStorage();
        const intervalId = setInterval(checkLocalStorage, 100);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        console.log("isAuthorized", isAuthorized);
        if (isAuthorized) {
            getChannelID();
        }
    }, [isAuthorized]);

    useEffect(() => {
        if (channelId) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_TOKEN, {
                cluster: "us2",
            });

            const channel = pusher.subscribe(channelId);

            getChatHistory().then((response) => {
                setUserMessages(response?.data);
                setShowMainScreen(false);
            });

            channel.bind("message", (data) => {
                setUserMessages((prevMessages) => [...prevMessages, data]);
            });

            return () => {
                pusher.unsubscribe(channelId);
            };
        }
    }, [channelId]);

    // get channel Id
    const getChannelID = async () => {
        if (!isAuthorized) return;
        let channelId = "";
        setSendMessageLoading(true);
        try {
            const res = await generateCode({});
            channelId = res?.data?.id;
            setChannelId((channelId || 0)?.toString());
        } catch (error) {
            console.log("Error while retrieving channel ID", error);
            //toast.warning("Error while retrieving channel ID")
        } finally {
            setSendMessageLoading(false);
        }

        return channelId;
    };

    const sendMessage = async () => {
        // debugger;
        if (
            (input && input?.trim().length > 0 && !sendMessageLoading) ||
            (file && !sendMessageLoading)
        ) {
            setSendMessageLoading(true);
            setShowMainScreen(false);
            let userObj = {
                type: "text",
                text: input,
            };
            if (file) {
                userObj = {
                    type: "file",
                    file: file,
                    text: input
                };
            }

            try {
                setInput("");
                // setFile(null);
                await SendMessage(userObj);
            } catch (error) {
                toast.warning("Error while sending message");
            }

            setSendMessageLoading(false);
        }
    };

    useEffect(() => {
        setWaitingForAgent(true);
        if (userMessages.length === 0) return;
        let agent = process.env.REACT_APP_AGENT_ID || "";
        let url = "";

        if (agent === "extension") {

            const urlParams = new URLSearchParams(window.location.search);
            url = urlParams?.get("url");
        }

        getNextMessage({url, agent})
            .then((response) => {
                setWaitingForAgent(response.data.waitingForAgent);
            })
            .catch((error) => {
            });
    }, [userMessages]);

    const getAuthTokenFromDB = async (token) => {
        const {data} = await getAuthFromToken({token});
        console.log("getAuthTokenFromDB", data);
        return data?.token;
    };

    if (loading) {
        return (
            <ThemeProvider attribute="class">
                <Loading/>
            </ThemeProvider>
        );
    }
    return (
        <>
            <ThemeProvider attribute="class">
                {isAuthorized ? (
                    <Layout
                        setIsAuthorized={setIsAuthorized}
                        showFloatingWindow={showFloatingWindow}
                        input={input}
                        sendMessageLoading={sendMessageLoading}
                        setShowFloatingWindow={setShowFloatingWindow}
                        setShowMainScreen={setShowMainScreen}
                        sendMessage={sendMessage}
                        setInput={setInput}
                        setFile={setFile}
                    >
                        {showMainScreen ? (
                            <MainPage setShowMainScreen={setShowMainScreen}/>
                        ) : (
                            <Transcripts
                                userMessages={userMessages}
                                waitingForAgent={waitingForAgent}
                            />
                        )}
                    </Layout>
                ) : (
                    <Login setAuthChanged={setAuthChanged} authChanged={authChanged}/>
                )}
            </ThemeProvider>
        </>
    );
}

export default App;
