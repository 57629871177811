import { Button } from "../../ui/button";
import { Gem } from 'lucide-react';
import { useTheme } from 'next-themes';
import gem from '../../Assets/gem.png'
import { ReactComponent as FluentIcon } from '../../Assets/icons/fluent.svg';


const UpgradBtn = () => {
    const { theme } = useTheme();
    return (
        <>
            <Button className="build-up-upgrade-btn" style={{ fontSize: "14px", fontWeight: 700 }}>
                {/* <Gem size={23} />&nbsp; UPGRADE PLAN */}
                <FluentIcon width={24} height={24}/>&nbsp; UPGRADE PLAN
            </Button></>
    )
}

export default UpgradBtn