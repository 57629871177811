import React, {useEffect} from 'react';
import darkLogo from '../../Assets/darkLogo.png'

import './login.sass';
import { Button } from '../../ui/button';
import { UserIcon, X } from 'lucide-react';

const Login = ({setAuthChanged, authChanged}) => {
    useEffect(() => {
        const interval = setInterval(() => {
            setAuthChanged(Date.now())
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    },[]);

    return <>
        <div className='bu-cross-icon' onClick={() => {
            localStorage.setItem('showFloatingScreen', false);
            const event = new CustomEvent('localStorageChange', {
                detail: { key: 'showFloatingScreen', value: "false" }
            });
            window.dispatchEvent(event);
            window?.parent?.postMessage({ type: 'extension-closed' }, '*');
        }}>
            <X size={24} />
        </div>
        <div className='bu-login-wrap'>
            <img
                className="bu-logo-img"
                src={darkLogo}
                alt="Logo"
            />
            <span className='bu-title'>Welcome back!</span>
            <span className='bu-sub-title'>Sign in to continue</span>
            <Button className="bu-menu-upgrade-btn" style={{ color: '#FFFFFF' }} onClick={() => {
                window.open('https://buildup.am/oauth/', '_blank');
            }}>
                <UserIcon width={24} height={24} />&nbsp; Sign In
            </Button>
        </div>
    </>
}

export default Login;
