import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as BifilesIcon } from "../../../Assets/icons/bi_files.svg";
import "./comps-style.scss";

const AttachFile = ({ open, handleClose, uploadFile, setInput, setError }) => {
  const wrapperRef = useRef(null);
  const [base64File, setBase64File] = useState(""); // State to store the Base64 string
  const [fileName, setFileName] = useState(""); // State to store file name

  const handleClickOutside = (event) => {
    if (event.target.id === "clip-btn") {
      return;
    }
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to handle file selection and convert it to Base64
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name); // Set file name in state
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setBase64File(reader.result); // Set the Base64 string in state
      };
      reader.onerror = (error) => {
        console.error("Error converting file to Base64:", error);
        setError(true); // Set error state to true
      };
    }
  };

  // Function to handle upload by sending the Base64 string to the uploadFile function
  const handleAttach = () => {
    if (base64File) {
      uploadFile(base64File, fileName); // Send Base64 string and file name to uploadFile function
      setFileName(""); // Clear the file name state
    } else {
      alert("Please select a file to attach.");
    }
  };

  if (!open) return null;

  return (
    <div ref={wrapperRef} className="bu-popover-wrapper">
      <div className="bu-file-upload">
        <BifilesIcon className="bu-icon" />
        <p>
          Drop files here,{" "}
          <label htmlFor="file">
            browse files
            <input id="file" hidden type="file" onChange={handleFileChange} />
          </label>{" "}
          Supports pdf, ppt, txt, docx
        </p>
        <span>Max 10 files up to 50 MB each</span>

        {/* Display selected file name if a file is chosen */}
        {fileName && (
          <div className="selected-file">
            <p>
              Selected file: <strong>{fileName}</strong>
            </p>
          </div>
        )}
      </div>
      <div className="bu-url-field">
        <input
          onChange={(e) => setInput(e.target.value)}
          type="text"
          placeholder="https://example.com"
        />
        <button className="bu-btn" onClick={handleAttach}>
          Attach
        </button>
      </div>
    </div>
  );
};

export default AttachFile;
