import React from 'react'

function HTMLMessage({ message }) {
  return (
    <div
    onClick={() => console.log(message)}
    dangerouslySetInnerHTML={{ __html: message?.payload?.html }}
  />
  
  )
}

export default HTMLMessage