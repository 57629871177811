import { Button } from "../../ui/button";
// icons
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ImageIcon from "@mui/icons-material/Image";
import pdfIcon from "../../Assets/pdf-icon (1).png";
import pptIcon from "../../Assets/ppt-icon.png";
import csvIcon from "../../Assets/csv-icon.png";
import docs from "../../Assets/docx-icon.png";
import { Download } from "lucide-react";

function FileMessage({ message, toast }) {
  const getFileTypeIcon = (type) => {
    switch (type) {
      case "application/pdf":
        return <img src={pdfIcon} alt="PDF Icon" width={20} height={20} />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <img src={docs} alt="Word Icon" width={20} height={20} />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <img src={csvIcon} alt="Excel Icon" width={20} height={20} />;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return <img src={pptIcon} alt="PPT Icon" width={20} height={20} />;
      case "image/png":
      case "image/jpeg":
        return <ImageIcon size={20} />;
      case "application/octet-stream":
        return <DocumentScannerIcon size={20} />;
      default:
        return null;
    }
  };
  const handleDownload = (file, type) => {
    // Initiate file download
    const link = document.createElement("a");
    link.href = file;
    link.download = type;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success(`${type} downloaded!`);
  };
  return (
    <div
      className={`flex flex-col ${
        message.sender === "user" ? "text-white" : ""
      }`}
    >
      {message.text && <div className="py-4 px-3">{message.text}</div>}
      <div
        className={`flex items-center flex-col gap-2 rounded-[11px]`}
      >
      {message?.payload?.mimeType.includes('image') && <div className="w-full max-w-[368px] h-auto">
          <img className="w-full h-full object-cover" src={message.file} alt="Preview not available" />
        </div>}
       <div className="flex items-center">
       {getFileTypeIcon(message.payload.mimeType)}
        <span className="ms-2">{message.payload.mimeType}</span>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => window.open(message.file, "_blank")}
          className={`ml-2 ${
            message.sender === "user" ? "hover:!bg-white/10" : ""
          }`}
        >
          <Download
            size={20}
            onClick={() =>
              handleDownload(message.file, message.payload.mimeType)
            }
          />
        </Button>
      </div>
       </div>
    </div>
  );
}

export default FileMessage;
