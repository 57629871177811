import { Button } from "../../ui/button";
import { Gem } from 'lucide-react';
import { useTheme } from 'next-themes';
import gem from '../../Assets/gem.png';
import { ReactComponent as FluentIcon } from '../../Assets/icons/fluent.svg';

const MenuUpgradebtn = () => {
    const { theme } = useTheme();
    return (
        <>
            <Button className="build-up-menu-upgrade-btn" style={{ color: '#FFFFFF' }} >
                <FluentIcon width={24} height={24}/>&nbsp; UPGRADE PLAN
            </Button>
        </>
    )
}

export default MenuUpgradebtn