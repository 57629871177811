import { useTheme } from "next-themes";
import { Copy, Download, RotateCw } from "lucide-react";
import { toast, Toaster } from "sonner";
import nightShare from "../../Assets/nightShare.png";
import lightShare from "../../Assets/lightShare.png";
// css
import "./index.scss";
import { useEffect } from "react";
import FileMessage from "../MessageTypes/FileMessage";
import YouTubeMessage from "../MessageTypes/YouTubeMessage";
import HTMLMessage from "../MessageTypes/HTMLMessage";

const Index = ({ userMessages, waitingForAgent }) => {
  const { theme } = useTheme();

  const handleCopyText = (text) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("Copy");
    textField.remove();
    toast.success("Text Copied");
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".build-up-transcript-chat");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [userMessages]);

  const allMessages = [...userMessages];
  if (waitingForAgent) {
    allMessages.push({
      payload: {
        text: (
          <div
            style={{
              width: 100,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div className="dot-pulse"></div>
          </div>
        ),
      },
      isUser: false,
      loading: true,
    });
  }

  return (
    <div className="build-up-transcript-container">
      <div className="flex-grow p-4 space-y-2 build-up-transcript-chat">
        {allMessages?.map((message, index) => {
          message.sender = message?.isUser ? "user" : "bot";
          message.text = message?.payload?.text;
          message.file = message?.payload?.file;

          // const youtubeUrl = '' //getYouTubeEmbedUrl(message.text);
          return (
            <>
              <div
                key={index}
                className={`flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={` bg-major text-minor max-w-full overflow-hidden ${
                    message.sender === "user"
                      ? "build-up-user-chat"
                      : "build-up-bot-chat"
                  }`}
                >
                  {message.type === "youtube" ? (
                    <YouTubeMessage message={message} />
                  ) : message?.type === 'file' ? (
                    <FileMessage toast={toast} message={message} />
                  ) : message?.type === "html" ? (
                    <HTMLMessage message={message} />
                  ) : (
                    message.text
                  )}
                </div>
              </div>
              {message?.sender === "bot" && !message.loading && (
                <div
                  className={`${
                    theme === "dark"
                      ? "build-up-icons-btn-dark"
                      : "build-up-icons-btn-light"
                  } flex space-x-2   gap-2`}
                >
                  <Copy
                    size={18}
                    onClick={() => handleCopyText(message?.text)}
                    style={{ marginLeft: "5px" }}
                  />
                  <RotateCw size={18} />
                  <img
                    src={theme == "dark" ? nightShare : lightShare}
                    style={{ height: "18px", width: "18px", cursor: "pointer" }}
                  />
                </div>
              )}
            </>
          );
        })}
      </div>
      {/* <InputBase /> */}
      <Toaster position="top-center" />
    </div>
  );
};

export default Index;
