import React from "react";

function YouTubeMessage({ message }) {
  return (
    <div style={{ width: "100%" }}>
      {/* <div style={{ marginBottom: "10px", marginTop: "15px" }}>
                        Here's a link to the Yes Theory YouTube channel: <br />
                        <a
                          href={`https://www.youtube.com/watch?v=${message.payload.videoId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#F1F894" }}
                        >
                          Yes Theory - YouTube (Youtube)
                        </a>
                        .
                      </div> */}
      <iframe
        style={{
          marginTop: "5px",
          marginBottom: "5px",
          width: "368px",
          height: "240px",
        }}
        src={`https://www.youtube.com/embed/${message.payload.videoId}`}
        frameBorder="0"
        allowFullScreen
        title="YouTube Video"
      ></iframe>
      {/* <div style={{ marginTop: "20px" }}>
                        <span
                          style={{
                            fontWeight: 550,
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          Additionally, you can watch one of their recent
                          videos, "Living 5 DAYS in Complete Darkness," here:{" "}
                          <a
                            href="https://www.youtube.com/watch?v=VIDEO_ID"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Living 5 DAYS in Complete Darkness - YouTube
                          </a>
                          .
                        </span>
                      </div> */}
    </div>
  );
}

export default YouTubeMessage;
