import client from "../axios";
import {GET_ENROLLMENT, SEND_MESSAGE_CODE, LOGIN, POST_NEXT} from "../Endpoints/AllEndPoints";
import axios from "axios";

export const getAuthFromToken = async (payload) => {
    return await axios.post(process.env.REACT_APP_BASE_API_URL+LOGIN, payload);
};

export const generateCode = async (payload) => {
    return await client.get(GET_ENROLLMENT);
};

export const SendMessage = async (payload) => {
    return await client.post(SEND_MESSAGE_CODE, payload);
};

export const getChatHistory = async () => {
    return await client.get(SEND_MESSAGE_CODE);
};

export const getNextMessage = async (payload) => {
    return await client.post(POST_NEXT, payload);
};
