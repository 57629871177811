import React, { useRef, useEffect } from 'react';
import { ReactComponent as AddIcon } from '../../../Assets/icons/add-icon.svg';
import { ReactComponent as DriveIcon } from '../../../Assets/icons/drive-icon.svg';
import { ReactComponent as OnedriveIcon } from '../../../Assets/icons/onedrive-icon.svg';
import './comps-style.scss';

const ImageUpload = ({ open, handleClose, uploadFile }) => {
    const wrapperRef = useRef(null);
    const fileInputRef = useRef(null);

    const handleClickOutside = (event) => {
        if (event.target.id === 'image-btn') {
            return;
        }
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            handleClose();
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Ensure only image files are allowed
            if (!file.type.startsWith('image/')) {
                alert('Please upload an image file.');
                return;
            }

            // Convert image to Base64 URL
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result; // The Base64 string
                uploadFile(base64String); // Call the uploadFile function with the Base64 string
                handleClose(); // Close the popover
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!open) return null;

    return (
        <div ref={wrapperRef} className="bu-popover-image-upload-wrapper">
            <button className="bu-btn light-btn">
                <DriveIcon />
                Connect to Google Drive
            </button>
            <div className="divider" />
            <button className="bu-btn light-btn">
                <OnedriveIcon />
                Connect to Microsoft OneDrive
            </button>
            <button
                className="bu-btn"
                style={{ marginTop: '12px' }}
                onClick={() => fileInputRef.current.click()}
            >
                <AddIcon className="bu-icon" />
                Upload from computer
            </button>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            />
        </div>
    );
};

export default ImageUpload;
